import React from 'react';
import ErrorMain from './components/ErrorMain';

const Error = () => {
    return (
        <>
            <ErrorMain/>
        </>
    );
};

export default Error;